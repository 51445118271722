import React from 'react';

const LoanAgreementCom = (props) => {
    return (
        <div className='M-loanAgreementCom'>
            <p>Dear Customer,</p>
            <p>Welcome to KING CASH LENDING INVESTORS INC. We are committed to providing you with professional and efficient loan services. As our esteemed customer, your satisfaction and trust are our greatest pursuits. Before you use our loan products, please carefully read the following detailed loan agreement to fully understand our service terms, application process, repayment policies, and customer rights protection measures.</p>
            <div>
                <p className='subtitle'>Loan Service Provider Details</p>
                <p>- Company: KING CASH LENDING INVESTORS INC.</p>
                <p>- SEC Registration Number: CS202104554</p>
                <p>- Official Email: <span className='email'>services@kingcashlending.com</span></p>
            </div>
            <div>
                <p className='subtitle'>Overview of Loan Products</p>
                <p>- Loan Amount: Based on your credit evaluation, we offer loan amounts ranging from a minimum of ₱4,000 to a maximum of ₱60,000 to meet the financial needs of different customers.</p>
                <p>- Loan Interest Rate(APR): The annual interest rate ranges from 20% to 30%, with the specific rate determined based on your credit history.</p>
                <p>- Loan Term: The loan period is flexible, covering 92 days to 365 days, allowing you to choose according to your repayment capacity.</p>
            </div>
            <div>
                <p className='subtitle'>Loan Application Process</p>
                <p>- Online Application: Visit our official website or download the official app, fill in your personal information, and upload the required documents.</p>
                <p>- Data Review: After submission, our professional team will quickly review your information to ensure its authenticity and completeness.</p>
                <p>- Loan Approval: Once approved, you will receive a loan approval notification, and the loan funds will be directly deposited into your designated bank account.</p>
            </div>
            <div>
                <p className='subtitle'>Loan Application Eligibility</p>
                <p>- Filipino Citizens: Applicants must be legal citizens holding valid Philippine identification documents.</p>
                <p>- Age Limit: Applicants must be between 18 and 60 years old.</p>
                <p>- No Major Criminal Record: Applicants must have a good credit record without serious violations or criminal behavior.</p>
            </div>
            <div>
                <p className='subtitle'>Repayment Guide</p>
                <p>- Diverse Repayment Methods: We offer various repayment options such as automatic deduction, bank transfer, and online payment to ensure a simple and convenient repayment process.</p>
                <p>- Repayment Reminder Service: We will send regular repayment reminders via SMS, email, or app notifications to help you avoid late fees.</p>
                <p>- Early Repayment Discount: We support early repayment without additional fees, helping you save on interest costs.</p>
            </div>
            <div>
                <p className='subtitle'>Overdue Handling and Credit Management</p>
                <p>- Overdue Communication Channels: If you encounter repayment difficulties, please contact our customer service team in a timely manner. We will provide deferred repayment or installment plans based on your specific situation.</p>
                <p>- Impact on Credit Report: Late repayments will be recorded in your credit report and may affect your eligibility for future credit products.</p>
            </div>
            <div>
                <p className='subtitle'>Customer Rights and Dispute Resolution</p>
                <p>- Customer Complaint Handling: We have a dedicated customer service department responsible for handling customer complaints and suggestions, ensuring that your rights are promptly addressed.</p>
                <p>- Legal Recourse: If disputes arise during the service process and internal mediation is ineffective, you have the right to file a lawsuit with the competent court, and we will comply with the court's ruling.</p>
            </div>
            <div>
                <p className='subtitle'>Contact Us</p>
                <p>- Official Email: <span className='email'>services@kingcashlending.com</span></p>
                <p>- Company Address: Washington Cor Taft 1301, Pasay City</p>
                <p>We promise to always be customer-centric, continuously optimize our service processes, and improve the customer experience. Whatever questions or assistance you need, we will do our utmost to ensure your loan journey is smooth and secure.</p>
            </div>
        </div>
    )
}

export default LoanAgreementCom;