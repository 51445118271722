import React, { useState, useEffect } from "react";
import Lottie from 'react-lottie';
import animationData from "../../common/json/loading.json"; // 替换为我的lottie 文件路径

const LottieLoader = (props) => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        }
    }
    const styles = {
        loaderContainer: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center', // 巨中
            background: 'transparent',
            zIndex: 2000,
        },
    };
    return (
        <div className="M-loading" style={styles.loaderContainer}>
            <Lottie options={defaultOptions} height={100} width={100} />
        </div>
    )
}

export default LottieLoader;
