import React, { Fragment } from "react";
import Button from "../button";
import "./index.less";
import closeIcon from "../../common/images/closeIcon.png";
import bankError_normalTop from "../../common/images/bankError/bankError_normalTop.png";
import bankError_limitTop from "../../common/images/bankError/bankError_limitTop.png";
import bankError_errorTop from "../../common/images/bankError/bankError_errorTop.png";

const ErrorModal = (props) => {
    const { loanFailedType, loanFailedTitle, loanFailedTxt, btnArray, isClose, closeFunc, children } = props;
    return (
        <Fragment>
        {/*<div className="M-errorModal">*/}
            <div className="M-errorModal_mask"></div>
            <div className="M-errorModal_info" style={{background: loanFailedType == 1 || loanFailedType == 7 ? "linear-gradient( 180deg, #FFEDBE 0%, #F6F6F7 12%, #F6F6F7 100%)" : loanFailedType == 2 || loanFailedType == 99 ? "linear-gradient( 180deg, #FFD6C4 0%, #F6F6F7 12%, #F6F6F7 100%)" : "linear-gradient( 180deg, #DFF391 0%, #F6F6F7 12%, #F6F6F7 100%)"}}>
                {isClose ? <img src={closeIcon} className="closeIcon" onClick={() => closeFunc()} /> : null}
                <div className="infoArea">
                    {loanFailedType == 0 ? <img src={bankError_normalTop} className="bankError_normal" /> : loanFailedType == 1 || loanFailedType == 7 ? <img src={bankError_limitTop} className="bankError_limit" /> : loanFailedType == 2 || loanFailedType == 99 ? <img src={bankError_errorTop} className="bankError_error" /> : null}
                    <p className="infoArea_loanFailedTitle">{loanFailedTitle || "-"}</p>
                    {children}
                    <p className="infoArea_loanFailedTxt">{loanFailedTxt || "-"}</p>
                </div>
                {btnArray && btnArray.length == 1 ? (
                    <div className="infoArea_btn">
                        <Button
                        content={btnArray[0].content}
                        clickFunc={btnArray[0].clickFunc} />
                    </div>
                ) : null}
                {btnArray && btnArray.length > 1 ? (
                    <div className="btnArea">
                        {btnArray.map((item, index) => {
                            return (
                                <div className="btnArea_btn" key={"errModalI" + index}>
                                    <Button
                                    content={item.content}
                                    clickFunc={item.clickFunc}
                                    btnStyle={item.btnStyle} />
                                </div>
                            )
                        })}
                    </div>
                ) : null}
            </div>
        {/*</div>*/}
        </Fragment>
    )
}

export default ErrorModal;