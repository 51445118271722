import React from "react";
import { Qs } from "../../common/js/tools";
import './index.less';

const params = Qs();

const SafeArea = (props) => {
    return (
        <div className="safeArea">
            {params.notch == 1 ? (
                <div className="App_top"></div>
            ) : params.notch == 0 ? (
                <div className="paddingTop"></div>
            ) : null}
        </div>
    )
}

export default SafeArea;