export default {
    loanDetailConfirm: "/inlan/fatherToday", // 确认用款页详情
    loanDetailPush: "/inlan/anythingTheyd", // 确认用款
    loanDetails: "/inlan/kirrinHispipe", // 订单详情页
    googleScore: "/inlan/directionJulians", // google评分
    userReloan: "/inlan/thoseLittle", // 原卡重试确认订单
    loanPlayDetail: "/inlan/astoundedSpoils", // 获取还款计划信息
    getLoanPayment: "/inlan/heatherDismounting", // 获取还款方式
    delayDetail: "/inlan/horseTrees", // 展期详情页
    cardInit: "/inlan/seafogsJulian", // 获取绑卡信息（第五项）
    bindCardNew: "/inlan/londonTimes", // 提交绑卡（第五项）
    userCardList: "/inlan/shoveNorth", // 用户银行卡列表
    changeOrderAccount: "/inlan/thereLeaving", // 更换银行卡（会触发重新打款）
    contactKfInfo: "/inlan/afterImight", // 获取客服页面数据
    generateVcode: "/inlan/theYelled", // 获取还款code码
    autoConfirmButton: "/inlan/bakCloke", // 取消自动确认用款
    // prohibitedProduct: "/uiengllli", // 禁止产品详情 - 小黑屋
}