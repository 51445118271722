import React, { useState } from 'react';
import './index.less';


const Button = (props) => {
    const { content, clickFunc, children, btnStyle, btnClass, isIcon } = props;
    return (
        <div className="M-button">
            {/* 中间固定内容 */}
            <div 
            className={"M-button_content " + btnClass}
            onClick={clickFunc}
            style={btnStyle}>
                {/* {children} */}
                <p>{content}</p>
            </div>
        </div>
    );
    /*render() {}*/
}

export default Button;