import React, { useState, useEffect} from "react";
// import { useSearchParams, useLocation } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import qs from 'qs';
import { setTitle } from "../../common/js/tools";
import { ToastType } from "../../common/js/toastList";
import { loanDetailConfirmService, loanDetailPushService, autoConfirmButtonService } from "../../axios/service";
import { uploadRiskLoan, setNavExpansion, setNavColor } from "../../common/js/navigateTools";
import Button from "../../components/button";
import ErrorModal from "../../components/errorModal";
import Modal from "../../components/modal";
import LoanAgreementCom from "../../components/loanAgreementCom";
import SafeArea from "../../components/safeArea";
import './confirmOfLoan.less';

import bankError_confirm_errorIcon from "../../common/images/bankError/bankError_confirm_errorIcon.png";
import bankError_confirm_limitIcon from "../../common/images/bankError/bankError_confirm_limitIcon.png";
import bankError_confirm_normalIcon from "../../common/images/bankError/bankError_confirm_normalIcon.png";
import confirm_goBank from "../../common/images/confirmOfLoan/confirm_goBank.png";
import confirm_countdownIcon from "../../common/images/confirmOfLoan/confirm_countdownIcon.png";

const ConfirmOfLoan = (props) => {
    // 路由params
    const location = useLocation();
    // console.log(qs.parse(location.search.substring(1, location.search.length)));
    // const params = Qs();
    const params = qs.parse(location.search.substring(1, location.search.length));
    const paramsDetail = `goods=${params.goods}&beach=${params.beach}&boiled=${params.boiled}&thirdhard=${params.thirdhard}&threemonths=${params.threemonths}&boating=${params.boating}&attraction=${params.attraction}&smallvillage=${params.smallvillage}&notch=${params.notch}`;

    // 跳转路由
    const navigate = useNavigate();

    var timer = null;
    // state数据
    const [amount, setAmount] = useState(""); // 传值时传的字段 不带金额符号的借款金额
    const [periods, setPeriods] = useState(""); // 期限 - 传值
    const [termUnit, setTermUnit] = useState(""); // // 期限类型 - 传值
    const [amountTxt, setAmountTxt] = useState(""); // 带金额符号的借款金额
    const [amount_show, setAmountShow] = useState(""); // 展示用金额 （有逗号没有金额符号）
    const [details, setDetails] = useState([]); // 借款数据
    const [repay_plans, setRepayPlans] = useState([]); // 还款计划
    const [note, setNote] = useState(""); // 首期还款即结清提示
    const [firstPayTime, setFirstPayTime] = useState(""); // 第一期还款时间
    const [firstPeriodDay, setFirstPeriodDay] = useState(""); // 第一期借款期限
    const [productLogo, setProductLogo] = useState(""); // 产品logo
    const [productName, setProductName] = useState(""); // 产品名字
    const [isChekedAgreement, setIsChekedAgreement] = useState(true); // 借款协议的选择状态
    const [cardType, setCardType] = useState(1); // 卡的类型 1.ewallet 2 bank 3 线下便利店
    const [bankCard, setBankCard] = useState(""); // 银行账户号
    const [bankName, setBankName] = useState(""); // 银行名称
    const [bankText, setBankText] = useState(""); // 银行卡文案
    const [actualAmount, setActualAmount] = useState(0); //到账金额
    const [orderNo, setOrderNo] = useState(""); // 订单号
    const [isAgreeShow, setIsAgreeShow] = useState(false); // 是否显示同意借款协议
    const [isArrowShow, setIsArrowShow] = useState(false); // 是否显示跳转绑卡列表页
    
    const [isBoxBank, setIsBoxBank] = useState(0); // 是否弹框 1弹 0不弹
    const [loanFailedTxt, setLoanFailedTxt] = useState(""); // 弹框里的文字
    const [loanFailedTitle, setLoanFailedTitle] = useState(""); // 弹框里的标题
    const [loanFailedType, setLoanFailedType] = useState(0); // 0 正常银行卡，1和7限额银行卡，2是错误账户银行卡 99未知错误银行卡

    const [isConfirmLoan, setIsConfirmLoan] = useState(0); // 展示自动确认用款倒计时
    const [confirmLoanAutoTime, setConfirmLoanAutoTime] = useState(0); // 自动倒计时时间
    const [confirmAutoTimes, setConfirmAutoTimes] = useState("00:00:00"); // 展示的自动倒计时时间

    const [isShowAgreementModal, setIsShowAgreementModal] = useState(false); // 是否显示协议的弹框

    useEffect(() => {
        setTitle("Loan Information");
        setNavExpansion(1); // 是否隐藏头部
        setNavColor("#222222", "");

        getLoanData();
        
        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    // 获取确认用款详情
    const getLoanData = async () => {
        let data = await loanDetailConfirmService();
        setAmount(data.secretly || 0);
        setPeriods(data.basket);
        setTermUnit(data.bilberry);
        setAmountTxt(data.victor || "");
        setAmountShow(data.bakeli || "");
        setDetails(data.baint || []);
        setRepayPlans(data.swhere || []);
        setNote(data.marked || "");
        setFirstPayTime(data.mistgot);
        setFirstPeriodDay(data.truant);
        setProductLogo(data.tlisten || "");
        setProductName(data.yousee || "");
        setCardType(data.vegot);
        setBankCard(data.banks || "");
        setBankName(data.gleamed || "");
        setBankText(data.blokLike || "");
        setActualAmount(data.oflong || "");
        setOrderNo(data.entirely || "");
        setIsAgreeShow(data.mistcame || 0);
        setIsArrowShow(data.afternoon || 0);

        setIsBoxBank(data.instealthy || 0);
        setLoanFailedTxt(data.polished || "");
        setLoanFailedTitle(data.knowed || "");
        setLoanFailedType(data.button || "");

        setIsConfirmLoan(data.loloike || 0);
        let auto_time = data.ziziee || 0;
        setConfirmLoanAutoTime(data.ziziee || 0);
        timerInterval(auto_time);
    }

    // 改变借款协议选中状态
    const changeAgreementCheckStatus = () => {
        setIsChekedAgreement(!isChekedAgreement);
    }
    // 跳转合同详情
    const goAgreement = () => {
        // window.location.href = `/droveSimply`;
        // props.history.push("/droveSimply");
        navigate("/droveSimply");
    }
    // 跳转换绑卡列表
    const changeAccount = () => {
        // props.history.push(`/greatauntsAfter?dogssuddenly=${orderNo}&manybare=${params.manybare}&${paramsDetail}`);
        // navigate(`/greatauntsAfter?dogssuddenly=${orderNo}&manybare=${params.manybare}&${paramsDetail}`);
        window.location.href = `/greatauntsAfter?dogssuddenly=${orderNo}&manybare=${params.manybare}&${paramsDetail}`;
    }
    // 关闭状态弹框
    const closeErrorModal = () => {
        setIsBoxBank(0);
    }
    // 去借款
    const goLoan = async () => {
        if (!isChekedAgreement) {
            ToastType("Toast", "Please check the note first");
            return;
        }
        let startTimeD = new Date();
        let startTime = `${parseInt(startTimeD.getTime() / 1000)}`;
        let data = await loanDetailPushService({
            amount: amount, // 借款金额
            periods: periods, // 期限
            termUnit: termUnit, // 期限类型
        });
        uploadRiskLoan(params.manybare, startTime);
        data.likeat && window.location.replace(`${data.likeat}&${paramsDetail}`); // 跳转到订单详情页
        // data.likeat && props.history.replace(`${data.likeat}&${paramsDetail}`); // 跳转到订单详情页
        // data.likeat && navigate(`${data.likeat}&${paramsDetail}`, {replace: true}); // 跳转到订单详情页
    }
    // 根据是否显示协议而进行弹框还是跳转
    const confirmFunc = () => {
        if(isAgreeShow) {
            setIsShowAgreementModal(true);
            return;
        } else {
            goLoan();
        }
    }
    // 倒计时
    // 倒计时转换
    const setCountDown = (time) => {
        let count = time;
        if(count > 0) {
            let hour = Math.floor(count / 3600);
            let minute = Math.floor((count / 60) % 60);
            let second = Math.floor(count % 60);
            let h = hour >= 10 ? hour : `0${hour}`;
            let m = minute >= 10 ? minute : `0${minute}`;
            let s = second >= 10 ? second : `0${second}`;
            setConfirmAutoTimes(`${h}:${m}:${s}`);
        }
    }
    const timerInterval = (auto_time) => {
        let time = auto_time;
        timer = setInterval(() => {
            if(time > 0) {
                time -= 1;
                setConfirmLoanAutoTime(time);
                setCountDown(time);
            } else {
                clearInterval(timer);
                // goLoan(); // 时间到直接去借款
            }
        }, 1000);
    }
    // 取消自动确认
    const cancelAutoConfirm = async () => {
        let data = await autoConfirmButtonService({
            orderNo: orderNo,
        });
        getLoanData();
    }

    return (
        <div className="P-confirmOfLoan">
            <SafeArea />
            {/* 顶部借款金额 */}
            <div className="P-confirmOfLoan_amount">
                <p className="amount_title">Loan Amount</p>
                <p className="amount">{amountTxt || ""}</p>
                {/* 倒计时后自动确认 */}
                {isConfirmLoan ? <div className="amount_countdown">
                    <div className="countdown_tips">
                        <img src={confirm_countdownIcon} className="confirm_countdownIcon" />
                        <p>Submit Automatically After <span>{confirmAutoTimes || ""}</span></p>
                    </div>
                    <p className="countdown_cancel" onClick={() => cancelAutoConfirm()}>Cancel</p>
                </div> : null}
            </div>
            {/* 借款信息区域 */}
            <div className="P-confirmOfLoan_infoArea">
                {/* 银行卡信息区域 */}
                <div className="infoArea_bank">
                    <div className="bank_info">
                        <p className="name">{bankName || "Name of Bank"}</p>
                        <div className="card">
                            {loanFailedType == 0 ? <img src={bankError_confirm_normalIcon} className="error_tipsIcon" /> : loanFailedType == 1 || loanFailedType == 7 ? <img src={bankError_confirm_limitIcon} className="error_tipsIcon" /> : loanFailedType == 2 || loanFailedType == 99 ? <img src={bankError_confirm_errorIcon} className="error_tipsIcon" /> : null}
                            <p className="card_num">{bankCard || "-"}</p>
                        </div>
                    </div>
                    {isArrowShow ? <img src={confirm_goBank} onClick={() => changeAccount()} className="confirm_goBank" /> : null}
                </div>
                {/* 还款计划信息区域 */}
                {repay_plans && repay_plans.length > 0 && (
                <div className="infoArea_repay">
                    <div className="repay_title">
                        <div className="title_time">
                            <p>Initial Repayment</p>
                            <p>{firstPayTime || ""}</p>
                        </div>
                        <p className="title_note">{note || ""}</p>
                    </div>
                    <ul className="repay_info">
                        <p className="repay_info_title">Repayment Plan</p>
                        {repay_plans.map((item, index) => {
                            return (
                                <li key={"conf_repayI" + index}>
                                    <div className="info ellipsis">
                                        <div className="info_amount">
                                            <p>{item.veheard || ""}</p>
                                            <p>{item.secretly || ""}</p>
                                        </div>
                                        <div className="info_date">
                                            <p>{item.skinned || ""}</p>
                                            <p>{item.thrilled || ""}</p>
                                        </div>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                </div>)}
                {/* 产品信息 */}
                <div className="infoArea_product">
                    <p>Product Name</p>
                    <div className="product">
                        {productLogo ? <img src={productLogo} className="productLogo" /> : null}
                        <p>{productName || ""}</p>
                    </div>
                </div>
            </div>
            {/* 固定底部区域 */}
            <div className="P-confirmOfLoan_bottom">
                {/* 借款协议 */}
                {/* 按钮 */}
                <div className="bottom_btnArea">
                    <Button
                    content="Confirm"
                    clickFunc={() => confirmFunc()} />
                </div>
            </div>
            {/* 银行卡错误提示弹框 */}
            {isBoxBank ? (
                <ErrorModal
                loanFailedType={loanFailedType}
                loanFailedTitle={loanFailedTitle}
                loanFailedTxt={loanFailedTxt}
                btnArray={[{
                    content: "Replace",
                    clickFunc: () => changeAccount(),
                    btnStyle: {background: "#222222", color: "#BEEF00", marginBottom: "10px"}
                }, {
                    content: "Continue",
                    clickFunc: () => closeErrorModal(),
                    btnStyle: {}
                }]}>
                    {loanFailedType != 0 ? (
                        <div className="p-confirmOfLoan_errorBank" style={{background: loanFailedType == 1 || loanFailedType == 7 ? "#FFF0CA" : "#FFE4D8"}}>
                            <p className="bankName">{bankName || "Receiving Account"}</p>
                            <div className="errorBank_info">
                                {loanFailedType == 1 || loanFailedType == 7 ? <img src={bankError_confirm_limitIcon} className="error_tipsIcon" /> : loanFailedType == 2 || loanFailedType == 99 ? <img src={bankError_confirm_errorIcon} className="error_tipsIcon" /> : null}
                                <p className="bankNum">{bankCard || "-"}</p>
                            </div>
                        </div>
                    ) : null}
                </ErrorModal>
            ) : null}
            {/* 借款协议弹框 */}
            {isShowAgreementModal ? 
            <Modal
            titleContent="Loan agreement"
            close={() => setIsShowAgreementModal(false)}>
                <div className="agreementArea">
                    <div className="agreementArea_agreement">
                        <LoanAgreementCom />
                    </div>
                    <div className="agreementArea_btn">
                        <Button
                        content="I Understand"
                        clickFunc={() => goLoan()} />
                    </div>
                </div>
            </Modal> : null}
        </div>
    )
}

export default ConfirmOfLoan;

