import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion } from '../../common/js/navigateTools';
import LoanAgreementCom from '../../components/loanAgreementCom';
import './agreement.less';

const LoanAgreement = (props) => {
    useEffect(() => {
        setTitle('Loan Agreement');
        setNavExpansion(0);
    }, [])
    return (
        <div className="P-agreement">
            <p className="title">Loan Agreement</p>
            <LoanAgreementCom />
        </div>
    );
}

export default LoanAgreement;

