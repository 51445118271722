import { Toast } from 'antd-mobile';

export function ToastType(type, content, onClose) {
    switch(type) {
        case "Toast": 
            Toast.show({content: content});
            break;
        case "loading":
            Toast.show({icon: 'loading', content: 'loading...'});
            break;
        case "success":
            Toast.show({icon: 'success', content: content});
            break;
        case "fail":
            Toast.show({icon: 'fail', content: content})
            break;
    }
}