import React, { useState, useEffect } from 'react';
import { setTitle } from "../../common/js/tools";
import { setNavExpansion, setNavColor, jumpToEmail, callPhoneMethod, jumpToWhatsapp, jumpToEmailNative } from '../../common/js/navigateTools';
import { contactKfInfoService } from "../../axios/service";
import SafeArea from "../../components/safeArea/index";
import './customerService.less';
import custom_topIcon from "../../common/images/custom/custom_topIcon.png";
import custom_jumpArrow from "../../common/images/custom/custom_jumpArrow.png";

const CustomerService = (props) => {
    const [contactList, setContactList] = useState([]); // 展示list数据
    const [emailTitle, setEmailTitle] = useState(""); // 邮箱标题
    useEffect(() => {
        setTitle('Customer Service');
        setNavExpansion(1); // 是否隐藏头部导航栏
        setNavColor("#222222", "");

        // 获取客服信息
        const getContractList = async () => {
            let data = await contactKfInfoService();
            setContactList(data || []);
        }
        getContractList();

        window.addEventListener("pageshow", function(event) {
            if (event.persisted) {
              window.location.reload();
            }
        });
    }, [])
    const jumpDetailContact = (item) => {
        switch (item.undid) {
            case "email":
                // jumpToEmail(item.shelteredplace);
                jumpToEmailNative(item.shelteredplace, "Contact Us", "");
                break;
            case "phone":
                callPhoneMethod(item.shelteredplace);
                break;
            case "whatsapp":
                jumpToWhatsapp(item.shelteredplace);
                break;
            case "feedback":
                jumpToEmailNative(item.shelteredplace, emailTitle, "");
                break;
        }

    }
    return (
        <div className="P-customerService">
            <SafeArea />
            <div className='P-customerService_tips'>
                <img src={custom_topIcon} className='custom_topIcon' />
                <div className='tips'>
                    <p className='tips_1'>Hello!</p>
                    <p className='tips_2'>If you encounter any issues, please contact us. We are dedicated to resolving your concerns</p>
                </div>
            </div>
            <div className='P-customerService_content'>
                {contactList && contactList.length > 0 && (
                <ul className='P-customerService_content_list'>
                    {contactList.map((item, index) => {
                        return (
                            <li className='P-customerService_content_list_li' key={"contactItem" + index} onClick={() => jumpDetailContact(item)}>
                                <div className='list_con_right'>
                                    {item.theexcitement ? (<img src={item.theexcitement}  />) : null}
                                    <div className='list_con_p'>
                                        <p className='list_con_p_top'>{item.thrilled || "none"}</p>
                                        <p>{item.raised || "none"}</p>
                                    </div>
                                </div>
                                <img src={custom_jumpArrow} className='list_con_left' />
                            </li>
                        )
                    })}
                </ul>
                )}
            </div>
        </div>
    );
}

export default CustomerService;

