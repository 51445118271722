import React, { useEffect } from 'react';
import { setTitle } from '../../common/js/tools';
import { setNavExpansion } from '../../common/js/navigateTools';
import './agreement.less';

const PrivacyAgreement = (props) => {
    useEffect(() => {
        // 隐私协议
        setTitle('Privacy Agreement');
        setNavExpansion(0);
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className="P-agreement">
            <p className="title">Privacy Policy</p>
            <p>Thank you for choosing us as your financial partner.</p>
            <p>We are committed to providing you with efficient and convenient loan services. At the same time, we understand the importance of user data security and privacy protection. To this end, we have formulated a detailed privacy policy to explain how we collect, use, store, protect, and share your personal information under specific conditions. Please read and understand this privacy policy carefully before using our products and services. By using our services, you agree to all the terms of this policy.</p>
            <p>If you have any questions, please feel free to contact us via our official email at <span className='email'>services@kingcashlending.com</span> or through the feedback channels within our products.</p>
            <div>
                <p className='subtitle'>Collection and Use of Personal Information</p>
                <p>We deeply understand the importance of personal information to each user. Therefore, we follow these principles when collecting personal information:</p>
                <p>- Principle of Minimal Necessity: We only collect information necessary to provide services, such as phone numbers, email addresses, geographic location information, and facial recognition data, for identity verification, risk assessment, and fraud prevention.</p>
                <p>- Clear Purpose: All collected personal information will be used to enhance service quality and security, and to comply with relevant legal and regulatory requirements.</p>
                <p>- User's Right to Know: We will clearly inform you of the purpose of the information before collecting it and obtain your consent when necessary.</p>
                <p>- Facial Data: We will collect your facial data for authentication to ensure that your authentication information is authentic and reliable. Your facial data will be stored for 7 days, after which your order including your personal information (including facial data) will be deleted.</p>
            </div>
            <div>
                <p className='subtitle'>Storage and Protection of Personal Information</p>
                <p>- Data Encryption: We use industry-leading data encryption technology to ensure the security of your personal information during transmission and storage.</p>
                <p>- Access Control: We strictly limit internal employee access to personal information. Only authorized personnel can access sensitive data.</p>
                <p>- Regular Audits: We implement regular security audits to check the effectiveness of data protection measures and promptly discover and fix potential security vulnerabilities.</p>
            </div>
            <div>
                <p className='subtitle'>Sharing and Disclosure of Personal Information</p>
                <p>We promise not to share, transfer, or publicly disclose your personal information without your explicit consent, except in the following situations:</p>
                <p>- Legal Requirements: When required by laws, regulations, legal processes, or government agencies.</p>
                <p>- Protection of Rights: In emergencies, to protect the major legitimate rights and interests such as the life, health, and property safety of you or others, but it is difficult to obtain your timely consent.</p>
                <p>- Business Cooperation: We may share information with our business partners, but only to the extent necessary to provide services, and our partners must comply with the same privacy protection standards.</p>
            </div>
            <div>
                <p className='subtitle'>User Rights and Choices</p>
                <p>- Right to Access: You can query the personal information we hold about you at any time.</p>
                <p>- Right to Correct: If your personal information is incorrect, you can request us to correct it.</p>
                <p>- Right to Delete: In certain circumstances, you can request us to delete your personal information.</p>
                <p>- Right to Object: You can withdraw your consent at any time, preventing us from continuing to use your personal information.</p>
            </div>
            <div>
                <p className='subtitle'>Contact Us</p>
                <p>We value your opinions and suggestions. If you have any questions about our privacy policy or need further assistance, please contact us through the following means:</p>
                <p>- Official Email: <span className='email'>services@kingcashlending.com</span></p>
                <p>- Office Address: Washington Cor Taft 1301, Pasay City</p>
            </div>
            <div>
                <p className='subtitle'>Updates and Changes</p>
                <p>We will update this privacy policy in a timely manner based on changes in laws and regulations and business development needs. Any significant changes will be notified to you in a prominent position within our app so that you can understand the latest policy content in a timely manner.</p>
                <p>Thank you again for your trust and support. We are committed to continuously optimizing privacy protection measures to provide you with a safer and more reliable service experience. If you have any questions or suggestions about our privacy policy, please feel free to contact us. Let us work together to create a more transparent and fair financial environment.</p>
            </div>
            <div>
                <p className='subtitle'></p>
                <p></p>
            </div>
            <div>
                <p className='subtitle'></p>
                <p></p>
            </div>
            <div>
                <p className='subtitle'></p>
                <p></p>
            </div>
            <div>
                <p className='subtitle'></p>
                <p></p>
            </div>
            <div>
                <p className='subtitle'></p>
                <p></p>
            </div>
            <div>
                <p className='subtitle'></p>
                <p></p>
            </div>
            <div>
                <p className='subtitle'></p>
                <p></p>
            </div>
            <div>
                <p className='subtitle'></p>
                <p></p>
            </div>
            <div>
                <p className='subtitle'></p>
                <p></p>
            </div>
        </div>
    );
}

export default PrivacyAgreement;

