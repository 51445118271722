import React, { Component } from 'react';
import { Qs, setTitle } from '../../../common/js/tools';
// import fruad from "../../../common/images/banner/fruad.png";
// const params = Qs();
import "./index.less";


export default class FraudBanner extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        setTitle("Anti-Fraud Guide");
    }
    componentWillUnmount() {}
    render() {
        return (
            <div className='f-banner-wrap'>
                {/* <img src={fruad} /> */}
            </div>
        )
    }
}